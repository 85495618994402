.App {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.App-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.blink {
  animation: blink-animation 1.2s steps(5, start) infinite;
}

.grungy-text {
  font-family: 'Creepster', cursive;
  font-size: 2em; /* Adjust size as needed */
  color: #ff0000; /* Adjust color as needed */
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.App-header {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  z-index: 1; /* Ensure header is above the video */
}

.App-link {
  color: #61dafb;
}

.bubbles {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 0; /* Ensure bubbles are above the video but below the header */
}

.bubble {
  position: absolute;
  bottom: -100px;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: bubble-animation 10s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubble img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@keyframes bubble-animation {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) scale(1.5);
    opacity: 0;
  }
}

/* Randomize bubble positions and sizes */
.bubble:nth-child(odd) {
  left: 10%;
  animation-duration: 12s;
  animation-delay: 2s;
}

.bubble:nth-child(even) {
  left: 80%;
  animation-duration: 8s;
  animation-delay: 4s;
}

.bubble:nth-child(3n) {
  left: 50%;
  animation-duration: 10s;
  animation-delay: 6s;
}

.bubble:nth-child(4n) {
  left: 30%;
  animation-duration: 14s;
  animation-delay: 8s;
}

.bubble:nth-child(5n) {
  left: 70%;
  animation-duration: 16s;
  animation-delay: 10s;
}


.App-footer {
  width: 100%;
  padding: 20px;
  padding-bottom: 10vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative; /* Changed from absolute to relative */
  color: white;
}

.iframe-container {
  display: flex; /* Changed from block to flex for centering */
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker background for better contrast */
  border-radius: 10px; /* Rounded corners for the iframe container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for a window effect */
  margin-bottom: 20px; /* Add margin to give more space below */
}
.moonshot-iframe {
  width: 80%;
  height: 700px; /* Increased height for more content display */
  border: none;
  border-radius: 10px; /* Rounded corners for the iframe */
}

@media (max-width: 768px) {
.App-logo {
height: 30vmin;
}
.App-header {
font-size: calc(8px + 2vmin);
}
.moonshot-iframe {
height: 300px; 
}
@media (max-width: 480px) {
.App-logo {
height: 20vmin;
}
.App-header {
font-size: calc(6px + 2vmin);
}
.moonshot-iframe {
height: 200px; 
}
}

.content-spacer {
  height: 100px; /* Adjust height as needed to push footer down */
}

}
